import React from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import AuthServices from '@mp/mp-auth-services';
import LoadingSuspense from '@mp/mp-loading-mask-suspense';

const Plataform = React.lazy(() => import('./Modules/Plataform'));

const Root = (props) => {

    const [token, setToken] = React.useState(AuthServices.getWithExpiry('token'));

    const changeAuth = (e) => {
        setToken(e.detail.token)
    }

    const resetToken = () => {
        setToken(null);
    }

    React.useEffect(() => {
        window.addEventListener("@mp/app-login/auth", changeAuth);
        return () => window.removeEventListener("@mp/app-login/auth", changeAuth);
    });

    React.useEffect(() => {
        window.addEventListener("@mp/app-login/logout", resetToken);
        return () => window.removeEventListener("@mp/app-login/logout", resetToken);
    });

    return (!token ? '' :
    //return (
        <LoadingSuspense>   
            <Router>
                <Plataform basename="/erp" {...props}/>
            </Router>
        </LoadingSuspense> 
    )
}

export default Root;